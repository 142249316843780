<template>
  <TransitionGroup v-if="group" :name="name">
    <slot />
  </TransitionGroup>
  <Transition v-else :name="name" :mode="mode">
    <div v-if="uniqueKey !== undefined && !group" :key="uniqueKey">
      <slot />
    </div>

    <slot v-else />
  </Transition>
</template>

<script lang="ts" setup>
import { TransitionsMode, TransitionsName } from './types'

withDefaults(defineProps<{
  name?: TransitionsName
  mode?: TransitionsMode
  uniqueKey?: PropertyKey
  group?: boolean
}>(), {
  name: 'fade',
  mode: 'default',
  group: false,
})
</script>

<style src="./transitions.style.css" />
