<template>
  <InputText
    v-model="inputDateInternal"
    class="input-date"
    :width="inputWidth"
    icon="date_range"
    :placeholder="placeHolderValue"
    :mask="dateMask"
    :disabled="disabled"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useVModel } from '@vueuse/core'
import IMask from 'imask'

import { InputText } from '../../Input'
import { DateFormatTypes } from '../../DatePicker/types'

const props = withDefaults(defineProps<{
  dataTestId?: string
  modelValue?: string
  dateFormat?: DateFormatTypes
  placeholder?: string
  disabled?: boolean
}>(), {
  dateFormat: 'MM/dd/yyyy',
  placeholder: '',
})

const emits = defineEmits<{
  'update:modelValue': [string]
}>()
const inputDateInternal = useVModel(props, 'modelValue', emits)

const dateMask = computed(() => ({
  mask: Date,
  pattern: props.dateFormat,
  blocks: {
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    dd: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
    },
    yy: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 99,
      maxLength: 2,
    },
    yyyy: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 2999,
      maxLength: 4,
    },
  },
  parse(str: string) {
    return str
  },
  format(date: string) {
    return date
  },
  autofix: true,
  lazy: true,
  overwrite: true,
}))

const inputWidth = computed(() => {
  switch (props.dateFormat) {
    case 'MM/yy':
      return '96px'
    case 'MM/yyyy':
      return '112px'
    case 'MM/dd/yyyy':
    default:
      return '133px'
  }
})

const placeHolderValue = computed(() => {
  if (props.placeholder) {
    return props.placeholder
  }
  else {
    return props.dateFormat.toUpperCase()
  }
})
</script>

<style scoped src="./inputdatefield.styles.css" />
