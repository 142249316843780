<template>
  <InputWrapper
    :disabled="disabled"
    :focused="focused"
    :error="inputError"
    :withValue="Boolean(modelValue)"
    :variant="variant"
    :size="size"
    flex
    col
    alignItems="start"
    class="cursor-text"
    @click="() => inputRef?.focus()"
  >
    <slot name="inputBefore" />
    <InputIcon :icon="icon" :endIcon="endIcon" :disabled="disabled" @clickEndIcon="props.onClickEndIcon">
      <input
        :id="id"
        ref="inputRef"
        :title="ariaTitle"
        :value="maskedValue"
        :name="name"
        :type="inputType"
        class="input-text"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :data-test-id="dataTestId"
        :readonly="readonly"
        @focus="onFocus($event)"
        @blur="onBlur($event)"
        @input="onInput($event)"
      >
      <template v-if="$slots.inputEnd" #inputEnd>
        <slot name="inputEnd" />
      </template>
    </InputIcon>
  </InputWrapper>
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue'

import type { Ref } from 'vue'
import IMask from 'imask'

import InputWrapper from '../InputWrapper/InputWrapper.vue'
import InputIcon from '../InputIcon.vue'

import type { InputMask, InputProps } from './types'

import { NullableMaskedNumber } from './utils'

const props = withDefaults(defineProps<InputProps>(), {
  modelValue: '',
  name: '',
  id: '',
  variant: 'default',
  ariaTitle: '',
  placeholder: '',
  size: 'lg',
  disabled: false,
  dataTestId: '',
  error: false,
  currency: false,
  mask: null,
  autocomplete: 'on',
  autoselect: false,
  readonly: false,
})

const emits = defineEmits(['update:modelValue', 'focus', 'blur', 'clickEndIcon'])

const inputRef = ref<HTMLInputElement>()

const isInitialRender = ref(true)

const focused = ref(false)

/*
  This is provided from <FormControl> component
  it returns boolean error state of vee validate
 */
const formControlHasError = inject<Ref<boolean>>('formControlHasError', ref(false))
const inputError = computed(() => formControlHasError.value || props.error || false)
const unmaskedValue = ref<string | number | null>()
const maskedValue = ref<string>(String(props.modelValue))

const maskProp = computed(() => {
  if (props.mask?.mask === Number) {
    // The Number Mask returns 0 when input is empty.
    // Here is workaround from https://github.com/uNmAnNeR/imaskjs/issues/432
    return new NullableMaskedNumber(props.mask as any)
  }

  return props.mask
})

const maskPipes = computed(() => {
  if (!maskProp.value)
    return null

  // if we have padFractionalZeros, we need to use it only on blur in order to avoid cursor and value jumping on input
  if (maskProp.value.padFractionalZeros) {
    return getMaskPipes({ ...maskProp.value, padFractionalZeros: false })
  }

  return getMaskPipes(maskProp.value)
})
const maskPipesOriginal = computed(() => {
  if (!maskProp.value)
    return null

  return getMaskPipes(maskProp.value)
})

function getMaskPipes(mask: InputMask) {
  const formatMaskPipe = IMask.createPipe(mask!)
  const parseMaskPipe = IMask.createPipe(
    mask!,
    IMask.PIPE_TYPE.MASKED,
    IMask.PIPE_TYPE.TYPED,
  )

  return { formatMaskPipe, parseMaskPipe }
}

const onFocus = (event: Event) => {
  focused.value = true
  emits('focus', event)

  if (props.autoselect) {
    inputRef.value?.select()
  }
}

const onBlur = (event: Event) => {
  focused.value = false

  if (maskProp.value) {
    const formatMask = maskPipesOriginal.value!.formatMaskPipe
    maskedValue.value = formatMask(String(unmaskedValue.value))
  }

  emits('blur', event)
}

const setValue = (_value: string | number, _isEdit: boolean) => {
  let value = String(_value)

  // @ts-expect-error TODO: rewrite form components
  if (maskProp.value?.maskType === 'dec2' && !_isEdit) {
    const pipes = isInitialRender.value ? maskPipesOriginal.value! : maskPipes.value!
    const { formatMaskPipe, parseMaskPipe } = pipes

    value = parseFloat(value).toFixed(2)

    maskedValue.value = formatMaskPipe(value)
    unmaskedValue.value = parseMaskPipe(value)
  }
  else if (maskProp.value) {
    // on initial render call use original mask pipes
    const pipes = isInitialRender.value ? maskPipesOriginal.value! : maskPipes.value!
    const { formatMaskPipe, parseMaskPipe } = pipes

    const masked = formatMaskPipe(value)
    let unmasked = parseMaskPipe(value)

    // if we have padFractionalZeros and value is empty, unmasked doesn't convert to null by some reason
    // so we need to do it manually as a workaround
    if (masked === '' && maskProp.value instanceof NullableMaskedNumber && maskProp.value.padFractionalZeros) {
      unmasked = null
    }

    maskedValue.value = masked
    unmaskedValue.value = unmasked
  }
  else {
    maskedValue.value = value
    unmaskedValue.value = value
  }
}

const onInput = (e: any) => {
  const value = e?.target.value

  const previousValue = maskProp.value ? unmaskedValue.value : maskedValue.value

  setValue(value, true)

  const updatedValue = maskProp.value ? unmaskedValue.value : maskedValue.value

  if (value !== previousValue && updatedValue !== previousValue) {
    emits('update:modelValue', updatedValue)
  }
}
const icon = computed(() => {
  return props.currency ? 'attach_money' : (props.icon || '')
})
const inputType = computed(() => {
  return maskProp.value ? 'text' : props.type
})

const init = () => {
  setValue(props.modelValue ?? '', false)
  isInitialRender.value = false
}

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue !== unmaskedValue.value) {
      if (isInitialRender.value) {
        return
      }

      const stringValue = String(newValue)

      // @ts-expect-error TODO: rewrite form components
      if (maskProp.value?.maskType === 'dec2' && stringValue.length > String(unmaskedValue.value).length + 4) {
        const { formatMaskPipe, parseMaskPipe } = maskPipesOriginal.value!
        const value = parseFloat(stringValue).toFixed(2)
        maskedValue.value = formatMaskPipe(value)
        unmaskedValue.value = parseMaskPipe(stringValue)
      }
      else if (maskProp.value) {
        const { formatMaskPipe, parseMaskPipe } = maskPipesOriginal.value!
        maskedValue.value = formatMaskPipe(stringValue)
        unmaskedValue.value = parseMaskPipe(stringValue)
      }
      else {
        maskedValue.value = stringValue
        unmaskedValue.value = newValue
      }
    }
  },
)

// set initial value
init()
</script>

<style scoped>
.input-text {
  @apply
    text-14 tracking-15 /* Values of Typography Body2 */
    text-textPrimary
    placeholder:text-textSecondaryLight
    grow
    h-[18px];
}
</style>
