<template>
  <Tippy
    ref="tippyRef"
    trigger="manual"
    :hideOnClick="false"
    px="0"
    py="0"
    @show="handleShow"
    @hidden="handleHide"
    @clickOutside="handleClickOutside"
  >
    <Box flex alignItems="center">
      <FormControl :id="id" :required="required" :label="label" :errorText="error" width="auto">
        <InputDateField
          v-model="date"
          :class="{ 'left-input': displayTime }"
          :dateFormat="dateFormat"
          :disabled="disabled"
          @focus="handleFocus"
          @blur="handleBlur"
        />
        <InputTimeField
          v-if="displayTime"
          v-model="time"
          :class="{ 'right-input': displayTime }"
          :disabled="disabled || timeDisabled"
        />
      </FormControl>
    </Box>
    <template #content>
      <DatePicker
        v-if="isShown"
        v-bind="$attrs"
        :selectedSingle="modelValueInternal"
        :dateFormat="dateFormat"
        :disabled="disabled"
        :min="min"
        :max="max"
        @selectedChoose="handleDatePicker"
      />
    </template>
  </Tippy>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'
import { useVModel } from '@vueuse/core'

import Box from '../Box/Box.vue'
import DatePicker from '../DatePicker/DatePicker.vue'
import FormControl from '../FormControl/FormControl.vue'
import Tippy from '../Tippy/Tippy.vue'
import { DateFormatTypes } from '../DatePicker/types'

import InputDateField from './InputDateField/InputDateField.vue'
import InputTimeField from './InputTimeField/InputTimeField.vue'
import { getHour, getMinute, isInRange, isLowerBound, isUpperBound } from './utils'

const props = withDefaults(defineProps<{
  dataTestId?: string
  id?: string
  modelValue: DateTime | null
  displayTime?: boolean
  required?: boolean
  disabled?: boolean
  timeDisabled?: boolean
  dateFormat?: DateFormatTypes
  label?: string
  error?: string
  locale?: string
  min?: DateTime
  max?: DateTime
}
>(), {
  displayTime: false,
  required: false,
  dateFormat: 'MM/dd/yyyy',
  locale: 'en-US',
  label: '',
  error: '',
})

const emits = defineEmits<{
  'update:modelValue': [DateTime]
  show: [void]
  hide: [void]
}>()

defineOptions({
  inheritAttrs: false,
})

const isShown = ref(false)
const tippyRef = ref<InstanceType<typeof Tippy> | null>(null)
const modelValueInternal = useVModel(props, 'modelValue', emits)

const handleShow = () => {
  emits('show')
  isShown.value = true
}
const handleHide = () => {
  emits('hide')
  isShown.value = false
}

const handleFocus = () => (tippyRef.value?.tippyInstance?.show())

const handleClickOutside = () => (tippyRef.value?.tippyInstance?.hide())

const handleDatePicker = (newDate: DateTime) => {
  modelValueInternal.value = newDate.set({
    hour: modelValueInternal.value?.hour,
    minute: modelValueInternal.value?.minute,
  }).setLocale(props.locale)
}

const handleBlur = (blurEvent: Event) => {
  const inputValue = (blurEvent.target as HTMLInputElement).value
  if (inputValue === '' || inputValue.length < props.dateFormat.length) {
    modelValueInternal.value = null
  }
}

const date = computed({
  get: () => {
    return props.modelValue?.toFormat(props.dateFormat)
  },
  set: (newDateString) => {
    if (!newDateString) {
      return
    }

    let newDate = DateTime.fromFormat(newDateString, props.dateFormat).set({
      hour: props.modelValue?.hour,
      minute: props.modelValue?.minute,
    }).setLocale(props.locale)

    if (props.min && isLowerBound(newDate, props.min, props.dateFormat)) {
      newDate = props.min.setLocale(props.locale)
    }

    if (props.max && isUpperBound(newDate, props.max, props.dateFormat)) {
      newDate = props.max.setLocale(props.locale)
    }

    if (isInRange(newDate, props.min, props.max)) {
      modelValueInternal.value = newDate
    }
  },
})

const time = computed({
  get: () => {
    return { hour: getHour(props.modelValue), minute: getMinute(props.modelValue) }
  },
  set: ({ hour, minute }) => {
    if (!hour || !minute) {
      return
    }

    if (props.modelValue && DateTime.isDateTime(props.modelValue)) {
      const newValue = props.modelValue.set({
        hour: parseInt(hour),
        minute: parseInt(minute),
      }).setLocale(props.locale)

      modelValueInternal.value = newValue
    }
  },
})
</script>

<style scoped src="./inputdate.styles.css" />
