<template>
  <Modal v-model="modelValue" :size="size" :title="title" @close="onCancel()">
    <Typography v-if="subtitle" variant="body1" color="textSecondary">
      {{ subtitle }}
    </Typography>

    <Box flex direction="row" spaceX="2" justify="end">
      <Button
        :variant="cancelVariant"
        :color="cancelColor"
        size="md"
        :disabled="loading"
        @click="onCancel()"
      >
        {{ cancelText }}
      </Button>
      <Button
        :variant="confirmVariant"
        :color="confirmColor"
        size="md"
        :disabled="loading"
        :loading="loading"
        @click="onConfirm()"
      >
        {{ confirmText }}
      </Button>
    </Box>
  </Modal>
</template>

<script setup lang="ts">
import { Modal } from '../Modal'
import Button from '../Button/Button.vue'
import Box from '../Box/Box.vue'
import Typography from '../Typography/Typography.vue'
import { ButtonColor, ButtonVariant } from '../Button'

const {
  title,
  subtitle = '',
  confirmText = 'Confirm',
  confirmVariant,
  confirmColor = 'primary',
  cancelText = 'Cancel',
  cancelVariant = 'outlined',
  cancelColor = 'default',
  loading,
  size = 'md',
} = defineProps<{
  title: string
  subtitle?: string
  confirmText?: string
  confirmVariant?: ButtonVariant
  confirmColor?: ButtonColor
  cancelText?: string
  cancelVariant?: ButtonVariant
  cancelColor?: ButtonColor
  loading: boolean
  size?: 'md' | 'lg' | 'xl'
}>()

const emit = defineEmits<{
  confirm: []
  cancel: []
}>()

const modelValue = defineModel<boolean>({ required: true })

const onConfirm = () => {
  emit('confirm')
}

const onCancel = () => {
  emit('cancel')
  modelValue.value = false
}
</script>
